'use client'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
const breakpoints = {
  base: '0em',
  xs: '280px',
  sm: '540px',
  md: '768px',
  lg: '990px',
  xl: '1124px',
  '2xl': '1280px',
  '3xl': '1440px',
  '4xl': '1650px',
}

const chakraTheme = extendTheme({
  colors: {
    red: {
      500: '#ED837F',
    },
    'lyk-green': '#24BFA3',
    'lyk-red': '#ED837F',
    'lyk-gray': '#ececec',
    'lyk-black': '#3e3e3e',
    'lyk-textborder': '#d9d9d9',
  },
  Radio: {
    baseStyle: {
      control: {
        _checked: {
          bg: 'lyk-green',
          borderColor: 'lyk-gray',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  fonts: {
    heading: 'var(--font-sans), system-ui',
    body: 'var(--font-sans), system-ui',
  },
  breakpoints,
})
export function Providers({ children }: { children: React.ReactNode }) {
  return (

          <ChakraProvider theme={chakraTheme}>

            {children}
          </ChakraProvider>

  )
}

